<template>
  <div>
    <v-text-field
        v-model="internalValue"
        outlined
        append-icon="mdi-magnify"
        :label="$t('search')"
        hide-details
        dense
    />
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    internalValue: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
</style>
