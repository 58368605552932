<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    permanent
    :width="drawer ? '280' : '60'"
    v-bind="$attrs"
    color="primary2"
  >
    <template v-slot:prepend>
      <span  v-if="drawer" class="d-flex justify-center align-center my-6 mr-5">
          <img :src="logoDrawer" class="mb-1"/>
         <div class="d-flex align-center">
          <span class="font-weight-bold font-size24 ml-2 white--text">Wellness</span>
          <span class="font-weight-bold font-size24 ml-2 white--text">Metric</span>
        </div>

        </span>
        <span v-else class="d-flex justify-center align-center my-6">
          <img :src="logoDrawer"/>
        </span>
    </template>

    <v-list nav dense density="compact">
      <!-- Render the first item in items -->
      <v-list-item
          v-if="items.length > 0"
          :to="items[0].path"
          :class="['layout-sider-item', drawerClass(items[0]), {'selected-item': items[0].isSelected}]"
          link
      >
        <v-list-item-icon>
          <img :src="items[0].icon" height="18px" class="ml-1" />
        </v-list-item-icon>
        <v-list-item-title class="text-start text-body-2">
          <span class="white-icon">{{ items[0].title }}</span>
        </v-list-item-title>
      </v-list-item>

      <!-- Render the Reports group -->
<!--      <v-list-group  class="custom-list-group" v-if="roleName === 'DOCTOR' || roleName === 'NURSE'" :value="!(roleName === 'DOCTOR' || roleName === 'NURSE')" color="white" no-action>-->
<!--        <template v-slot:activator>-->
<!--          <v-list-item class="ml-n1">-->
<!--            <v-list-item-icon>-->
<!--              <v-icon color="white" class="mdi-18px">mdi-file-chart</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title class="text-start text-body-2">-->
<!--              <span class="white-icon">{{$t('reports')}}</span>-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </template>-->

<!--        <v-list-item-->
<!--            v-for="item in reportItems"-->
<!--            :key="item.path"-->
<!--            :link="true"-->
<!--            :to="item.path"-->
<!--            :class="['layout-sider-item', drawerClass(item), {'selected-item': item.isSelected}]"-->
<!--        >-->
<!--          <v-list-item-icon>-->
<!--            <img style="margin-top: 3px" :src="item.icon" height="24px" :class="{ 'ml-n12': !drawer }"/>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-title class="text-left text-body-2 ml-n5">-->
<!--            <span class="white-icon">{{ item.title }}</span>-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list-group>-->

      <!-- Render the remaining items in items -->
      <v-list-item
          v-for="(item) in items.slice(1)"
          :key="item.title"
          :to="item.path"
          :class="['layout-sider-item', drawerClass(item), {'selected-item': item.isSelected}]"
          link
      >
        <v-list-item-icon>
          <img :src="item.icon" height="18px" class="ml-1" />
        </v-list-item-icon>
        <v-list-item-title class="text-start text-body-2">
          <span class="white-icon">{{ item.title }}</span>
        </v-list-item-title>
      </v-list-item>

      <!-- Render the hospital selector if role is DOCTOR or NURSE -->
      <div v-if="roleName === 'DOCTOR' || roleName === 'NURSE'" class="d-flex justify-center">
        <v-list-item class="text-center app-drawer-select-hospital">
          <div class="select-hospital" @click="branchSelector">
            <div v-if="drawer">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn :class="{ 'small-font': isTextTooLong }" width="265" color="primary" v-on="on" class="text-wrap">
                    <div class="d-flex align-center">
                      <img class="mr-1"  :src="HospitalWhite" height="22" v-on="on" alt="">
                      <span>  {{ userObject.userData?.hospitalAssignedData?.hospitalName }}</span>
                    </div>
                  </v-btn>
                </template>
                <!-- Localize -->
                <h6 class="white-color">{{$t('office-selector')}}</h6>
              </v-tooltip>
            </div>
            <div v-if="!drawer">
              <img :src="HospitalWhite" height="25">
            </div>
          </div>
        </v-list-item>
      </div>
      <div v-else></div>
    </v-list>

    <BranchSelectorDialog
		v-if="dialog"
		:dialog="dialog"
    @reload="reloadComponent"
    @false="dialog = false"
	/>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  logoDrawer,
  logoWithName,
  duplicate,
  dashboardWhite,
  devicesWhite,
  medicalNote,
  convertArrows,
  usersWhite,
  doctorWhite,
  hospitalWhite,
  organizationsWhite,
  billWhite,
  supportWhite,
  verifireWhite,
  mentalHealthWhite,
  medicalTeamWhite,
  logs,
} from '@/assets';
import BranchSelectorDialog from '../dialogs/BranchSelectorDialog.vue';

export default {
	name: 'DashboardCoreDrawer',
	components: { BranchSelectorDialog },
	data: () => ({
		logoWithName: logoWithName,
		mentalHealthWhite: mentalHealthWhite,
		HospitalWhite: hospitalWhite,
    DevicesWhite: devicesWhite,
    Devices: devicesWhite,
    logoDrawer: logoDrawer,
		dialog: false,
	}),
  computed: {
		...mapState(['pageSize']),
		...mapGetters({
			roleName: 'authentication/getRole',
			specialization: 'authentication/getSpecialization',
			userObject: 'authentication/userObject',
		}),
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DRAWER', val);
			},
		},
    // reportItems () {
    //   switch (this?.roleName) {
    //     // case 'SYSTEM_ADMIN':
    //     //   return [
    //     //     {
    //     //       title: this.$t('activities'),
    //     //       icon: activity,
    //     //       path: '/dashboardsystemadmin',
    //     //     },
    //     //   ];
    //     // case 'ORGANIZATION_ADMIN':
    //     //   return [
    //     //     {
    //     //       title: this.$t('activities'),
    //     //       icon: activity,
    //     //       path: '/dashboardAdmin',
    //     //     },
    //     //   ];
    //     // case 'HOSPITAL_ADMIN':
    //     case 'DOCTOR':
    //     case 'NURSE':
    //       return [
    //         {
    //           title: this.$t('activities'),
    //           icon: activity,
    //           path: '/dashboardFacility',
    //         },
    //       ];
    //     default:
    //       return [];
    //   }
    // },
		items () {
			switch (this?.roleName) {
				case 'SYSTEM_ADMIN':
					return [
            {
              title: this.$t('system-admin-dashboard'),
              icon: dashboardWhite,
              path: '/dashboardsystemadmin',
            },
						{
							title: this.$t('organizations'),
							icon: organizationsWhite,
							path: '/organizations',
						},
						{
							title: this.$t('hospitals'),
							icon: hospitalWhite,
							path: '/offices',
						},
						{
							title: this.$t('admins'),
							icon: usersWhite,
							path: '/admins',
						},
						{
							title: this.$t('support'),
							icon: supportWhite,
							path: '/support',
						},
						{
							title: this.$t('verifier'),
							icon: verifireWhite,
							path: '/verifier',
						},
						// {
						// 	title: this.$t('SA_Reports'),
						// 	icon: verifireWhite,
						// 	path: '/sareports',
						// },
            {
              title: this.$t('invoices'),
              icon: verifireWhite,
              path: '/invoices',
            },
            {
              title: this.$t('resident-mapping'),
              icon: convertArrows,
              path: '/residentMapping',
            },
            {
              title: this.$t('staff-mapping'),
              icon: convertArrows,
              path: '/medicalStaffMapping',
            },
            {
              title: this.$t('logs'),
              icon: logs,
              path: '/logs',
            },
					];
        case 'PARTNER':
          return [
            {
              title: this.$t('billing-reports'),
              icon: billWhite,
              path: '/partnerBillingReports',
            },
          ];

        case 'ORGANIZATION_ADMIN':
					// this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					// this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					// this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');

					if (this.userObject.userData?.organizationAssignedData?.wellnessFlagDefault) {
						return [
              {
                title: this.$t('Admin Dashboard'),
                icon: dashboardWhite,
                path: '/dashboardAdmin',
              },
							{
								title: this.$t('Organizations'),
								icon: organizationsWhite,
								path: '/organizations',
							},
							{
								title: this.$t('hospitals'),
								icon: hospitalWhite,
								path: '/offices',
							},
							{
								title: this.$t('admins'),
								icon: usersWhite,
								path: '/admins',
							},
							{
								title: this.$t('staff'),
								icon: doctorWhite,
								path: '/medicalstaff',
							},
							// {
							// 	title: this.$t('patients'),
							// 	icon: usersWhite,
							// 	path: '/patients',
							// },
							// {
							// 	title: this.$t('OA_Reports'),
							// 	icon: verifireWhite,
							// 	path: '/oareports',
							// },
							// {
							// 	title: this.$t('medicalTeams'),
							// 	icon: medicalTeamWhite,
							// 	path: '/medicalteam',
							// },
							{
								title: this.$t('billings'),
								icon: billWhite,
								path: '/billings',
							},
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
							// how to show or hide this based on condition
							{
								title: this.$t('monitoring-plans'),
								icon: devicesWhite,
								path: '/monitoring',
							},
              {
                title: this.$t('BillingsAuthorizationTemplates'),
                icon: duplicate,
                path: '/billingsettings',
              },
              {
                title: this.$t('UserBillingsAuthorization'),
                icon: billWhite,
                path: '/UserBillingSettings',
              },
              {
                title: this.$t('medical-note-category'),
                icon: medicalNote,
                path: '/medicalNotesCategory',
              },

						];
					} else {
						return [
              {
                title: this.$t('Admin Dashboard'),
                icon: dashboardWhite,
                path: '/dashboardAdmin',
              },

							{
								title: this.$t('Organizations'),
								icon: organizationsWhite,
								path: '/organizations',
							},
							{
								title: this.$t('hospitals'),
								icon: hospitalWhite,
								path: '/offices',
							},
							{
								title: this.$t('admins'),
								icon: usersWhite,
								path: '/admins',
							},
							{
								title: this.$t('staff'),
								icon: doctorWhite,
								path: '/medicalstaff',
							},
							// {
							// 	title: this.$t('patients'),
							// 	icon: usersWhite,
							// 	path: '/patients',
							// },
							// {
							// 	title: this.$t('OA_Reports'),
							// 	icon: verifireWhite,
							// 	path: '/oareports',
							// },
							// {
							// 	title: this.$t('medicalTeams'),
							// 	icon: medicalTeamWhite,
							// 	path: '/medicalteam',
							// },
							{
								title: this.$t('billings'),
								icon: billWhite,
								path: '/billings',
							},
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              {
                title: this.$t('BillingsAuthorizationTemplates'),
                icon: duplicate,
                path: '/billingsettings',
              },
              {
                title: this.$t('UserBillingsAuthorization'),
                icon: billWhite,
                path: '/UserBillingSettings',
              },
              {
                title: this.$t('medical-note-category'),
                icon: medicalNote,
                path: '/medicalNotesCategory',
              },
							// {
							// 	title: this.$t('monitoring-plans'),
							// 	icon: devicesWhite,
							// 	path: '/monitoring',
							// },
						];
					}
				case 'HOSPITAL_ADMIN':
					this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');
          if (this.userObject.userData.hospitalAssignedData.medicalInstitutionData.wellnessFlagDefault) {
					return [
            {
              title: this.$t('facility-dashboard'),
              icon: dashboardWhite,
              path: '/dashboardFacility',
            },
						{
							title: this.$t('staff'),
							icon: doctorWhite,
							path: '/medicalstaff',
						},
						{
							title: this.$t('patients'),
							icon: usersWhite,
							path: '/patients',
						},
						{
							title: this.$t('medicalTeams'),
							icon: medicalTeamWhite,
							path: '/medicalteam',
						},
						{
							title: this.$t('billings'),
							icon: billWhite,
							path: '/billings',
						},
						{
							title: this.$t('assessments'),
							icon: mentalHealthWhite,
							path: '/wellnessassessments',
						},
            {
              title: this.$t('BillingsAuthorizationTemplates'),
              icon: duplicate,
              path: '/billingsettings',
            },
            {
              title: this.$t('UserBillingsAuthorization'),
              icon: billWhite,
              path: '/UserBillingSettings',
            },
            {
              title: this.$t('medical-note-category'),
              icon: medicalNote,
              path: '/medicalNotesCategory',
            },
            {
              title: this.$t('resident-mapping'),
              icon: convertArrows,
              path: '/residentMapping',
            },
            {
              title: this.$t('staff-mapping'),
              icon: convertArrows,
              path: '/medicalStaffMapping',
            },
            // {
            //   title: this.$t('daily-reports'),
            //   icon: verifireWhite,
            //   path: '/dailyreports',
            // },
						// {
						// 	title: this.$t('test'),
						// 	icon: mentalHealthWhite,
						// 	path: '/mentalhealthtemplate',
						// },

					];
          } else {
            return [
              {
                title: this.$t('facility-dashboard'),
                icon: dashboardWhite,
                path: '/dashboardFacility',
              },
              {
                title: this.$t('staff'),
                icon: doctorWhite,
                path: '/medicalstaff',
              },
              {
                title: this.$t('patients'),
                icon: usersWhite,
                path: '/patients',
              },
              {
                title: this.$t('medicalTeams'),
                icon: medicalTeamWhite,
                path: '/medicalteam',
              },
              {
                title: this.$t('billings'),
                icon: billWhite,
                path: '/billings',
              },
              {
                title: this.$t('BillingsAuthorizationTemplates'),
                icon: duplicate,
                path: '/billingsettings',
              },
              {
                title: this.$t('UserBillingsAuthorization'),
                icon: billWhite,
                path: '/UserBillingSettings',
              },
              {
                title: this.$t('medical-note-category'),
                icon: medicalNote,
                path: '/medicalNotesCategory',
              },
              {
                title: this.$t('resident-mapping'),
                icon: convertArrows,
                path: '/residentMapping',
              },
              {
                title: this.$t('staff-mapping'),
                icon: convertArrows,
                path: '/medicalStaffMapping',
              },
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              // {
              //   title: this.$t('assessments'),
              //   icon: mentalHealthWhite,
              //   path: '/wellnessassessments',
              // },
            ];
          }
				case 'DOCTOR':
					this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');
          if (this.userObject.userData.hospitalAssignedData.medicalInstitutionData.wellnessFlagDefault) {
            return [
              // {
              //   title: this.$t('dashboardIconTooltip'),
              //   icon: dashboardWhite,
              //   path: '/dashboard',
              // },
              {
                title: this.$t('facility-dashboard'),
                icon: dashboardWhite,
                path: '/dashboardFacility',
              },
              {
                title: this.$t('staff'),
                icon: doctorWhite,
                path: '/medicalstaff',
              },
              {
                title: this.$t('patients'),
                icon: usersWhite,
                path: '/patients',
              },
              {
                title: this.$t('medicalTeams'),
                icon: medicalTeamWhite,
                path: '/medicalteam',
              },
              {
                title: this.$t('billings'),
                icon: billWhite,
                path: '/billings',
              },
              {
                title: this.$t('assessments'),
                icon: mentalHealthWhite,
                path: '/wellnessassessments',
              },
              {
                title: this.$t('monthly-review'),
                icon: verifireWhite,
                path: '/monthlyreview',
              },
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              // {
              // 	title: this.$t('mental-health-assignments'),
              // 	icon: mentalHealthWhite,
              // 	path: '/mentalhealthpatient',
              // },
            ];
          } else {
            return [
              // {
              //   title: this.$t('dashboardIconTooltip'),
              //   icon: dashboardWhite,
              //   path: '/dashboard',
              // },
              {
                title: this.$t('facility-dashboard'),
                icon: dashboardWhite,
                path: '/dashboardFacility',
              },
              {
                title: this.$t('staff'),
                icon: doctorWhite,
                path: '/medicalstaff',
              },
              {
                title: this.$t('patients'),
                icon: usersWhite,
                path: '/patients',
              },
              {
                title: this.$t('medicalTeams'),
                icon: medicalTeamWhite,
                path: '/medicalteam',
              },
              {
                title: this.$t('billings'),
                icon: billWhite,
                path: '/billings',
              },
              {
                title: this.$t('monthly-review'),
                icon: verifireWhite,
                path: '/monthlyreview',
              },
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              // {
              //   title: this.$t('assessments'),
              //   icon: mentalHealthWhite,
              //   path: '/wellnessassessments',
              // },
              // {
              // 	title: this.$t('mental-health-assignments'),
              // 	icon: mentalHealthWhite,
              // 	path: '/mentalhealthpatient',
              // },
            ];
          }
        case 'NURSE':
          this.$store.dispatch('roles/getRoles');
          this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
          this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
          this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');
          if (this.userObject.userData.hospitalAssignedData.medicalInstitutionData.wellnessFlagDefault) {
            return [
              // {
              //   title: this.$t('dashboardIconTooltip'),
              //   icon: dashboardWhite,
              //   path: '/dashboard',
              // },
              {
                title: this.$t('facility-dashboard'),
                icon: dashboardWhite,
                path: '/dashboardFacility',
              },
              {
                title: this.$t('staff'),
                icon: doctorWhite,
                path: '/medicalstaff',
              },
              {
                title: this.$t('patients'),
                icon: usersWhite,
                path: '/patients',
              },
              {
                title: this.$t('medicalTeams'),
                icon: medicalTeamWhite,
                path: '/medicalteam',
              },
              {
                title: this.$t('billings'),
                icon: billWhite,
                path: '/billings',
              },
              {
                title: this.$t('assessments'),
                icon: mentalHealthWhite,
                path: '/wellnessassessments',
              },
              {
                title: this.$t('monthly-review'),
                icon: verifireWhite,
                path: '/monthlyreview',
              },
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              // {
              // 	title: this.$t('mental-health-assignments'),
              // 	icon: mentalHealthWhite,
              // 	path: '/mentalhealthpatient',
              // },
            ];
          } else {
            return [
              // {
              //   title: this.$t('dashboardIconTooltip'),
              //   icon: dashboardWhite,
              //   path: '/dashboard',
              // },
              {
                title: this.$t('facility-dashboard'),
                icon: dashboardWhite,
                path: '/dashboardFacility',
              },
              {
                title: this.$t('staff'),
                icon: doctorWhite,
                path: '/medicalstaff',
              },
              {
                title: this.$t('patients'),
                icon: usersWhite,
                path: '/patients',
              },
              {
                title: this.$t('medicalTeams'),
                icon: medicalTeamWhite,
                path: '/medicalteam',
              },
              {
                title: this.$t('billings'),
                icon: billWhite,
                path: '/billings',
              },
              {
                title: this.$t('monthly-review'),
                icon: verifireWhite,
                path: '/monthlyreview',
              },
              // {
              //   title: this.$t('daily-reports'),
              //   icon: verifireWhite,
              //   path: '/dailyreports',
              // },
              // {
              //   title: this.$t('assessments'),
              //   icon: mentalHealthWhite,
              //   path: '/wellnessassessments',
              // },
              // {
              // 	title: this.$t('mental-health-assignments'),
              // 	icon: mentalHealthWhite,
              // 	path: '/mentalhealthpatient',
              // },
            ];
          }

        default:
					if (this.specialization?.doctorMetadataReadDto) {
						return [
							{
                title: this.$t('facility-dashboard'),
								icon: dashboardWhite,
								path: '/dashboardFacility',
							},
							{
								title: this.$t('medicalTeam'),
								icon: doctorWhite,
								path: '/medicalteam',
							},
							{
								title: this.$t('patients'),
								icon: usersWhite,
								path: '/patients',
							},
							// {
							// 	title: this.$t('mental-health-template'),
							// 	icon: brainWhite,
							// 	path: '/mentalhealthtemplate',
							// },
							// {
							// 	title: this.$t('mental-health-assignments'),
							// 	icon: mentalHealthWhiteAssign,
							// 	path: '/mentalhealthpatient',
							// },
						];
					} else {
						return [
							{
                title: this.$t('facility-dashboard'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'dashboard' ? dashboardWhite : dashboardWhite,
								path: '/dashboardFacility',
							},
							{
								title: this.$t('medicalTeam'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? doctorWhite : doctorWhite,
								path: '/medicalteam',
							},
							{
								title: this.$t('patients'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? usersWhite : usersWhite,
								path: '/patients',
							},
						];
					}
			}
		},
    isTextTooLong () {
      const hospitalName = this.userObject.userData?.hospitalAssignedData?.hospitalName || '';
      return hospitalName.length > 28;
    },
	},
	watch: {
		pageSize (val) {
			if (val.width < 800 && val) { this.drawer = false; } else if (val.width >= 800 && val === false) { this.drawer = true; }
		},
	},

	methods: {
    reloadComponent () {
      const currentPath = this.$route.path;
      const currentName = this.$route.name;

      if (currentName !== 'Patient Details') {
      this.$router.push('/emptyComponent').then(() => {
        this.$router.push({ path: currentPath, query: { reload: new Date().getTime() } });
      });
      } else {
        this.$router.push('/patients');
      }
    },
		logout () {
			this.$router.push({ name: 'Login' });
		},
		drawerClass (item) {
      const classes = [];
      if (this.$route.path === item.path) {
        classes.push('selected-item');
      }
			if (['/mentalhealthpatient'].includes(item.path)) {
				// Check if the current route path matches one of the specified paths
				if (this.$route.path === item.path) {
				classes.push('selected-item');
				}
			}
			if (item.path === '/dashboard') {
				if (item.path.substring(1) === this.$route.name.toLowerCase()) {
					classes.push('layout-sider-item-active-dashboard');
					classes.push('selected-item');
				} else {
					classes.push('layout-sider-item-deactive');
				}
			} else {
				if (this.$route.name === 'Patient Details') {
					if (item.path.substring(1) === 'patients') {
						classes.push('layout-sider-item-active-dashboard');
						classes.push('selected-item');
					} else {
						classes.push('layout-sider-item-deactive');
					}
				} else if (['/wellnessassessments'].includes(item.path)) {
					// Check if the current route path matches one of the specified paths
					if (this.$route.path === item.path) {
						classes.push('selected-item');
					}
				} else if (['/organizations', '/offices', '/oareports', '/sareports'].includes(item.path)) {
					// Check if the current route path matches one of the specified paths
					if (this.$route.path === item.path) {
						classes.push('selected-item');
					}
				} else {
					if (item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase()) {
						classes.push('layout-sider-item-active');
						classes.push('selected-item');
					} else {
						classes.push('layout-sider-item-deactive');
					}
				}
			}
			return classes.join(' ');
		},
		// iconClass (item) {
		// 	if (this.$route.name === 'Patient Details') {
		// 		return item.path.substring(1) === 'patients' ? 'icon-active' : 'icon-deactive';
		// 	}
		// 	const iconClass = item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'icon-active' : 'icon-deactive';
		// 	return iconClass;
		// },
		// titleClass (item) {
		// 	if (this.$route.name === 'Patient Details') {
		// 		return item.path.substring(1) === 'patients' ? 'white--text' : 'white--text';
		// 	}
		// 	return item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'white--text' : 'white--text';
		// },
		branchSelector () {
			// this.dialog = true;
			if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				this.dialog = true;
			} else {
				this.dialog = false;
			}
		},
	},
};
</script>

<style>
.custom-list-group .v-list-group__header .v-icon {
  color: white !important;
}
.transition-class{
  transform: translateX(-10px) !important;
  transition:0.5s !important;
}
</style>
